import React from 'react';
import './Privacy.css';

const Privacy = () => (
  <div className={'PrivacyContainer'}>
    <h3>Datenschutzerklärung</h3>
    <p>
      Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
      EU-Datenschutzgrundverordnung (DSGVO), ist:
    </p>
    <p>Alfred Helms</p>
    <h3>Ihre Betroffenenrechte</h3>
    <p>
      Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können
      Sie jederzeit folgende Rechte ausüben:
    </p>
    <ul>
      <li>
        Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung
        (Art. 15 DSGVO),
      </li>
      <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
      <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
      <li>
        Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
        gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
      </li>
      <li>
        Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)
        und
      </li>
      <li>
        Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
        haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
      </li>
    </ul>
    <p>
      Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit
      mit Wirkung für die Zukunft widerrufen.
    </p>
    <p>
      Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde
      wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres
      Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige
      Behörde.
    </p>
    <p>
      Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
      Anschrift finden Sie unter:{' '}
      <a
        href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'
        target='_blank'
        rel='nofollow noopener'
      >
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
      </a>
      .
    </p>
    <p></p>
    <h3>Kontaktformular</h3>
    <h4>Art und Zweck der Verarbeitung:</h4>
    <p>
      Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen
      Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen
      E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung
      der Anfrage und der anschließenden Beantwortung derselben. Die Angabe
      weiterer Daten ist optional.
    </p>
    <h4>Rechtsgrundlage:</h4>
    <p>
      Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf
      der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).
    </p>
    <p>
      Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine
      unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden
      zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen
      gespeichert.
    </p>
    <p>
      Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt
      die Verarbeitung der in das Kontaktformular eingegebenen Daten zur
      Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).
    </p>
    <h4>Empfänger:</h4>
    <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
    <h4>Speicherdauer:</h4>
    <p>
      Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.
    </p>
    <p>
      Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den
      gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach
      Ablauf dieser Fristen.{' '}
    </p>
    <h4>Bereitstellung vorgeschrieben oder erforderlich:</h4>
    <p>
      Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir
      können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen,
      Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.
    </p>
    <p></p>
    <h3>Verwendung von Google Maps</h3>
    <h3>Art und Zweck der Verarbeitung:</h3>
    <p>
      Auf dieser Webseite nutzen wir das Angebot von Google Maps. Google Maps
      wird von Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043,
      USA (nachfolgend „Google“) betrieben. Dadurch können wir Ihnen interaktive
      Karten direkt in der Webseite anzeigen und ermöglichen Ihnen die
      komfortable Nutzung der Karten-Funktion.
    </p>
    <p>
      Nähere Informationen über die Datenverarbeitung durch Google können Sie{' '}
      <a href='http://www.google.com/privacypolicy.html'>
        den Google-Datenschutzhinweisen
      </a>{' '}
      entnehmen. Dort können Sie im Datenschutzcenter auch Ihre persönlichen
      Datenschutz-Einstellungen verändern.
    </p>
    <p>
      Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang
      mit Google-Produkten
      <a href='http://www.dataliberation.org/'> finden Sie hier</a>.
    </p>
    <h4>Rechtsgrundlage:</h4>
    <p>
      Rechtsgrundlage für die Einbindung von Google Maps und dem damit
      verbundenen Datentransfer zu Google ist Ihre Einwilligung (Art. 6 Abs. 1
      lit. a DSGVO).
    </p>
    <h4>Empfänger:</h4>
    <p>
      Durch den Besuch der Webseite erhält Google Informationen, dass Sie die
      entsprechende Unterseite unserer Webseite aufgerufen haben. Dies erfolgt
      unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie
      eingeloggt sind, oder ob keine Nutzerkonto besteht. Wenn Sie bei Google
      eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet.
    </p>
    <p>
      Wenn Sie die Zuordnung in Ihrem Profil bei Google nicht wünschen, müssen
      Sie sich vor Aktivierung des Buttons bei Google ausloggen. Google
      speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der
      Werbung, Marktforschung und/oder bedarfsgerechter Gestaltung seiner
      Webseite. Eine solche Auswertung erfolgt insbesondere (selbst für nicht
      eingeloggte Nutzer) zur Erbringung bedarfsgerechter Werbung und um andere
      Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Webseite
      zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung
      dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten
      müssen.
    </p>
    <h4>Speicherdauer:</h4>
    <p>
      Wir erheben keine personenbezogenen Daten, durch die Einbindung von Google
      Maps.
    </p>
    <h4>Drittlandtransfer:</h4>
    <p>
      Google verarbeitet Ihre Daten in den USA und hat sich dem EU_US Privacy
      Shield unterworfen{' '}
      <a href='https://www.privacyshield.gov/EU-US-Framework'>
        https://www.privacyshield.gov/EU-US-Framework
      </a>
      .
    </p>
    <h4>Widerruf der Einwilligung:</h4>
    <p>
      Wenn Sie nicht möchten, dass Google über unseren Internetauftritt Daten
      über Sie erhebt, verarbeitet oder nutzt, können Sie in Ihrem
      Browsereinstellungen JavaScript deaktivieren. In diesem Fall können Sie
      unsere Webseite jedoch nicht oder nur eingeschränkt nutzen.
    </p>
    <h4>Bereitstellung vorgeschrieben oder erforderlich:</h4>
    <p>
      Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
      allein auf Basis Ihrer Einwilligung. Sofern Sie den Zugriff unterbinden,
      kann es hierdurch zu Funktionseinschränkungen auf der Website kommen.
    </p>
    <p></p>
    <h3>Änderung unserer Datenschutzbestimmungen</h3>
    <p>
      Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
      stets den aktuellen rechtlichen Anforderungen entspricht oder um
      Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B.
      bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die
      neue Datenschutzerklärung.
    </p>
    <h3>Fragen an den Datenschutzbeauftragten</h3>
    <p>
      Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail
      oder wenden Sie sich direkt an die für den Datenschutz verantwortliche
      Person in unserer Organisation.
    </p>
    <p></p>
    <p>
      <em>
        Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den
        Experten für{' '}
        <a
          href='https://www.activemind.de/datenschutz/datenschutzbeauftragter/'
          target='_blank'
          rel='noopener'
        >
          externe Datenschutzbeauftragte
        </a>{' '}
        (Version #2019-04-10).
      </em>
    </p>
  </div>
);

export default Privacy;
