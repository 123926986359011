import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { List, withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import PlantDetails from './PlantDetails';

import { Paper } from '@material-ui/core';

import articleData from '../data/Artikeldaten.json';

import './Plants.css';
import ReactResizeDetector from 'react-resize-detector';

interface Props {
  children: any;
  value: any;
  index: any;
}

function TabPanel(props: Props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className='PlantsTabPanel'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const CustomTab = withStyles({
  root: {
    fontSize: '1.2rem',
    textTransform: 'none'
  }
})(Tab);

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function Plants() {
  const [value, setValue] = React.useState('Obstgehölze');
  const [tabsShown, setTabsShown] = useState(true);
  const [expandedPlant, setExpandedPlant] = useState('');
  const handleChange = (_: any, newValue: any) => {
    setExpandedPlant('');
    setValue(newValue);
    setTabsShown(false);
  };
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width:600px)').matches
  );

  return (
    <div className='PlantsContainer'>
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={() => {
          setIsMobile(window.matchMedia('(max-width:600px)').matches);
        }}
      />
      <div className='PlantsTabContainer'>
        {(!isMobile || tabsShown) && (
          <Paper className='Paper'>
            <Tabs
              indicatorColor={undefined}
              textColor='inherit'
              value={value}
              centered
              variant='fullWidth'
              orientation={isMobile ? 'vertical' : 'horizontal'}
              onChange={handleChange}
            >
              {articleData.Warengruppendaten.find(
                (warengruppen) => warengruppen['@Name'] === 'Pflanzen'
              )?.Warengruppe.map((warengruppe) => (
                <CustomTab
                  label={warengruppe['@Name']}
                  value={warengruppe['@Name']}
                  {...a11yProps(0)}
                />
              ))}
            </Tabs>
          </Paper>
        )}
        {(!isMobile || !tabsShown) && (
          <TabPanel value={value} index={value}>
            {isMobile && (
              <button onClick={() => setTabsShown(true)} className='backButton'>
                <ArrowBackIcon /> zurück
              </button>
            )}
            <List className='PlantsList'>
              {articleData.Sortendaten.filter((sorte) => {
                return (
                  'Artikel' in sorte.Artikeldaten &&
                  sorte.Artikeldaten.Artikel.Artikelgruppe === value
                );
              }).map((sorte, index) => (
                <>
                  <PlantDetails
                    plant={{
                      scientific_name: sorte.Bezeichnung1,
                      common_name: String(sorte.Bezeichnung1_1),
                      details: {
                        description: String(sorte.Beschreibung),
                        image_urls:
                          index % 2 === 0
                            ? [
                                './images/PICT3_thumb.jpeg',
                                './images/PICT4_thumb.jpeg'
                              ]
                            : [
                                './images/PICT1_thumb.jpeg',
                                './images/PICT2_thumb.jpeg'
                              ]
                      }
                    }}
                    expanded={expandedPlant === sorte['BKS-Artikeltextnr']}
                    onClick={() => {
                      setExpandedPlant(String(sorte['BKS-Artikeltextnr']));
                    }}
                  ></PlantDetails>
                  <Divider className='ListItemDivider' />
                </>
              ))}
            </List>
          </TabPanel>
        )}
      </div>
    </div>
  );
}
