import React, { useState, useRef, MutableRefObject, useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import './Contact.css';
import ContactDetails from './ContactDetails';

const Contact = () => {
  const isMobile = window.matchMedia('(max-width:600px)').matches;
  const [errorRef, setErrorRef] =
    useState<MutableRefObject<HTMLInputElement | undefined>>();
  const name: MutableRefObject<HTMLInputElement | undefined> =
    useRef<HTMLInputElement>();
  const email = useRef<HTMLInputElement>();
  const telefone = useRef<HTMLInputElement>();
  const subject = useRef<HTMLInputElement>();
  const text = useRef<HTMLInputElement>();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsSubject = params.get('subject');
    if (paramsSubject && subject.current) {
      subject.current.value = 'Verfügbarkeit ' + paramsSubject;
    }
    window.history.replaceState({}, '', '/');
  }, [window.location]);

  const checkInput = (event: React.FormEvent | null) => {
    if (!name.current || !name.current.value) {
      setErrorRef(name);
      if (event) {
        event.preventDefault();
      }
      return false;
    } else if (!email.current || !email.current.value) {
      setErrorRef(email);
      if (event) {
        event.preventDefault();
      }
      return false;
    } else if (!subject.current || !subject.current.value) {
      setErrorRef(subject);
      if (event) {
        event.preventDefault();
      }
      return false;
    } else if (!text.current || !text.current.value) {
      setErrorRef(text);
      if (event) {
        event.preventDefault();
      }
      return false;
    } else {
      return true;
    }
  };

  const paddingBottom = '15px';

  const nameInput: JSX.Element = (
    <TextField
      error={errorRef === name}
      onChange={() => setErrorRef(undefined)}
      inputRef={name}
      name='name'
      inputProps={{
        onFocus: () => setErrorRef(undefined)
      }}
      style={{
        width: '100%',
        minWidth: '220px',
        paddingRight: '10px',
        paddingBottom: paddingBottom
      }}
      id='outlined-basic'
      label='Name'
      variant='outlined'
    />
  );

  const emailInput: JSX.Element = (
    <TextField
      error={errorRef === email}
      onChange={() => setErrorRef(undefined)}
      inputRef={email}
      name='email'
      inputProps={{
        onFocus: () => setErrorRef(undefined)
      }}
      style={{
        width: '100%',
        minWidth: '220px',
        paddingBottom: paddingBottom
      }}
      id='outlined-basic'
      label='E-Mail'
      variant='outlined'
    />
  );

  const telefoneInput: JSX.Element = (
    <TextField
      error={errorRef === telefone}
      onChange={() => setErrorRef(undefined)}
      inputRef={telefone}
      name='telefon'
      inputProps={{
        onFocus: () => setErrorRef(undefined)
      }}
      style={{
        width: '100%',
        minWidth: '220px',
        paddingRight: '10px',
        paddingBottom: paddingBottom
      }}
      id='outlined-basic'
      label='Telefon'
      variant='outlined'
    />
  );

  const subjectInput: JSX.Element = (
    <TextField
      error={errorRef === subject}
      onChange={() => setErrorRef(undefined)}
      inputRef={subject}
      name='betreff'
      inputProps={{
        onfocus: () => setErrorRef(undefined)
      }}
      style={{
        width: '100%',
        minWidth: '220px',
        paddingBottom: paddingBottom
      }}
      id='outlined-basic'
      label='Betreff'
      variant='outlined'
    />
  );

  const textInput: JSX.Element = (
    <TextField
      error={errorRef === text}
      onChange={() => setErrorRef(undefined)}
      inputRef={text}
      id='outlined-multiline-static'
      label='Nachricht'
      name='text'
      multiline
      rows={isMobile ? '4' : '6'}
      inputProps={{
        onfocus: () => setErrorRef(undefined)
      }}
      style={{ paddingBottom: paddingBottom }}
      variant='outlined'
    />
  );

  return (
    <div className='ContactContainer'>
      <div className='ContactColumn'>
        <p>
          Anschrift:
          <br />
          {!isMobile && <br />}
          Am Osterberg 5
          <br />
          21279 Appel
        </p>
        <ContactDetails />
      </div>
      <div>
        <br />
        Hier können Sie uns eine Nachricht hinterlassen:
        <br />
        <br />
      </div>
      <form
        className='ContactForm'
        name='mail'
        action='https://europe-west3-baumschule-helms-289209.cloudfunctions.net/send-mail'
        method='post'
        onSubmit={(event) => checkInput(event)}
      >
        {!isMobile && (
          <>
            <div className='Input'>
              {nameInput}
              {emailInput}
            </div>
            <div className='Input'>
              {telefoneInput}
              {subjectInput}
            </div>
            {textInput}
            <Button
              name='Submit'
              type='submit'
              value='Submit'
              variant='contained'
            >
              Senden
            </Button>
          </>
        )}
        {isMobile && (
          <>
            {nameInput}
            {emailInput}
            {telefoneInput}
            {subjectInput}
            {textInput}
            <Button
              name='Submit'
              type='submit'
              value='Submit'
              variant='contained'
            >
              Senden
            </Button>
          </>
        )}
      </form>
    </div>
  );
};

export default Contact;
