import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Plant from '../types/plant';

interface Props {
  plant: Plant;
  expanded: boolean;
  onClick: () => void;
}

const PlantDetails = ({ plant, expanded, onClick }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const listItemRef = useRef<HTMLDivElement>(null);

  const getImages = () => {
    if (plant.details?.image_urls) {
      return plant.details.image_urls.map((url) => {
        return { original: url, thumbnail: url };
      });
    }
    return [];
  };

  const images = getImages();

  useEffect(() => {
    if (expanded) {
      listItemRef.current?.scrollIntoView(true);
    }
  }, [expanded]);

  return (
    <ListItem
      style={{
        justifyContent: 'center'
      }}
      key={plant.scientific_name}
      onClick={onClick}
      innerRef={listItemRef}
    >
      <div
        ref={containerRef}
        className={`PlantItemContainer ${expanded && 'expanded'}`}
      >
        {plant.details?.image_urls && !expanded && (
          <ListItemIcon>
            <img src={plant.details?.image_urls[0]} className='PlantIcon' />
          </ListItemIcon>
        )}
        {expanded && plant.details && (
          <div className='DetailsContainer'>
            {plant.details.image_urls && (
              <div className='GalleryContainer'>
                <ImageGallery items={images} />
              </div>
            )}
          </div>
        )}
        <div style={{ cursor: 'initial' }}>
          <ListItemText
            primary={
              <span
                style={
                  plant.details && images ? { textDecoration: 'underline' } : {}
                }
              >
                {plant.common_name}
              </span>
            }
            secondary={plant.scientific_name}
          />
          {expanded && (
            <>
              <p>{plant.details?.description}</p>

              <p>
                Verfügbarkeit:{' '}
                <a href={`?subject=${plant.common_name}`}>Auf Anfrage</a>
              </p>
            </>
          )}
        </div>
      </div>
    </ListItem>
  );
};

export default PlantDetails;
