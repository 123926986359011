import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './Images.css';

const images = [
  {
    original: './images/PICT1.jpeg',
    thumbnail: './images/PICT1_thumb.jpeg'
  },
  {
    original: './images/PICT2.jpeg',
    thumbnail: './images/PICT2_thumb.jpeg'
  },
  {
    original: './images/PICT3.jpeg',
    thumbnail: './images/PICT3_thumb.jpeg'
  },
  {
    original: './images/PICT4.jpeg',
    thumbnail: './images/PICT4_thumb.jpeg'
  },
  {
    original: './images/PICT10.jpeg',
    thumbnail: './images/PICT10.jpeg'
  },
  {
    original: './images/PICT11.jpeg',
    thumbnail: './images/PICT11.jpeg'
  },
  {
    original: './images/PICT12.jpeg',
    thumbnail: './images/PICT12.jpeg'
  },
  {
    original: './images/PICT13.jpeg',
    thumbnail: './images/PICT13.jpeg'
  },
  {
    original: './images/PICT14.jpeg',
    thumbnail: './images/PICT14.jpeg'
  }
];

class Images extends React.Component {
  render() {
    return (
      <div className='ImagesContainer'>
        <div className='Gallery'>
          <ImageGallery items={images} />
        </div>
      </div>
    );
  }
}

export default Images;
