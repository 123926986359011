import React, { useRef, useEffect } from 'react';

const Map = () => {
  const canvas = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (canvas.current) {
      var mapOptions = {
        zoom: 13,
        center: new google.maps.LatLng(53.38705909999999, 9.7343228),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      const map = new google.maps.Map(canvas.current, mapOptions);

      const marker = new google.maps.Marker({
        map: map,
        position: {
          lat: 53.38705909999999,
          lng: 9.7343228
        }
      });

      const infowindow = new google.maps.InfoWindow({
        content:
          "<strong>Baumschule Helms</strong><div>Am Osterberg 5<br>21279 Appel<div><a target='_blank' href='https://www.google.com/maps/dir//53.38705909999999,9.7343228'><u>Anfahrt berechnen</u></a>"
      });

      google.maps.event.addListener(marker, 'click', function () {
        infowindow.open(map, marker);
      });

      infowindow.open(map, marker);
    }
  }, [canvas]);

  return <div style={{ height: '100%', width: '100%' }} ref={canvas}></div>;
};

export default Map;
