import React from 'react';
import './ContactDetails.css';

const ContactDetails = () => {
  const isMobile = window.matchMedia('(max-width:600px)').matches;
  return (
    <p className={'ContactDetailsContainer'}>
      <div className={'ContactDetailsRows'}>
        Tel.:
        <br />
        Fax.:
        <br />
        {!isMobile && <br />}
        E-Mail:
      </div>
      <div className={'ContactDetailsRows'}>
        <a href='tel:+494165 80517'>04165 80517</a>
        04165 81056
        <br />
        {!isMobile && <br />}
        <a href='mailto:mail@baumschule-helms.de' title='Mailen Sie uns'>
          mail@baumschule-helms.de
        </a>
      </div>
    </p>
  );
};

export default ContactDetails;
