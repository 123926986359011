import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      minWidth: 300,
      width: "100%"
    },
    image: {
      position: "relative",
      height: 300,
      [theme.breakpoints.down("xs")]: {
        width: "100% !important", // Overrides inline-style
        height: 300
      },
      "&:hover, &$focusVisible": {
        zIndex: 1,
        "& $imageBackdrop": {
          //opacity: 0.15
        },
        "& $imageMarked": {
          //opacity: 0
        },
        "& $imageTitle": {
          border: "3px solid currentColor"
        }
      }
    },
    focusVisible: {},
    imageTitle: {
      position: "relative",
      fontSize: "16pt",
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
        6}px`
    },
    imageMarked: {
      height: 3,
      width: 18,
      backgroundColor: theme.palette.common.white,
      position: "absolute",
      bottom: -3,
      left: "calc(50% - 9px)",
      transition: theme.transitions.create("opacity")
    }
  }));

type IProps = {
  title?: string;
  selected?: boolean; 
  onClick?: () => void;
}

const Button = ({title, selected, onClick}: IProps): JSX.Element => {
    const classes = useStyles();
      
    return (<ButtonBase
        focusRipple
        key={title}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        onClick={onClick}
        style={{
          height: 0,
          width: 200
        }}
      >
        <span>
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            className={classes.imageTitle}
          >
            {title}
            {selected && <span className={classes.imageMarked} />}
          </Typography>
        </span>
      </ButtonBase>)
}

export default Button;