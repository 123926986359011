import React, { useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import './App.css';
import Button from './Button';
import { ReactComponent as Logo } from './logo.svg';
import MenuIcon from '@material-ui/icons/Menu';
import Imprint from './Imprint';
import Privacy from './Privacy';
import Images from './Images';
import Home from './Home';
import Contact from './Contact';
import Map from './Map';
import {
  makeStyles,
  createTheme,
  ThemeProvider
} from '@material-ui/core/styles';
import MaterialButton from '@material-ui/core/Button';
import Plants from './plants/Plants';
//import WIFI from './WIFI';

const MenuItem = {
  HOME: 0,
  PLANTS: 6,
  IMAGES: 1,
  CONTACT: 2,
  MAP: 3,
  IMPRINT: 4,
  PRIVACY: 5,
  WIFI: 6
};

const useStyles = makeStyles(() => ({
  root: {
    color: '#FFFFFF',
    paddingLeft: '20px',
    paddingRight: '20px',
    height: '100%'
  }
}));

function App() {
  const [selectedItem, setSelectedItem] = useState(MenuItem.HOME);
  const [menuOpen, setMenuOpen] = useState(false);
  const [cookieInfo, setCookieInfo] = useState(
    !document.cookie.includes('cookies-accepted')
  );
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width:600px)').matches
  );

  const classes = useStyles();

  const selectItem = (item: any): void => {
    setMenuOpen(false);
    setSelectedItem(item);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    /* const wifi = params.get('wifi');
    if (wifi === 'true') {
      setSelectedItem(MenuItem.WIFI);
    } */

    const subject = params.get('subject');
    if (subject) {
      setSelectedItem(MenuItem.CONTACT);
    }
  }, [window.location]);

  const theme = createTheme({
    typography: {
      fontSize: 16
    }
  });

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={() => {
            setIsMobile(window.matchMedia('(max-width:600px)').matches);
          }}
        />
        <header
          onClick={() => {
            if (isMobile) {
              setMenuOpen(!menuOpen);
            }
          }}
          className={
            !isMobile || menuOpen ? 'App-header' : 'App-header-collapsed'
          }
        >
          <div className='Logo'>
            <Logo />
            <div className='Logo-text'>
              <div
                style={{
                  fontSize: '16pt',
                  fontWeight: 'bold',
                  paddingBottom: '4pt'
                }}
              >
                BAUMSCHULE
              </div>
              <div style={{ fontSize: '15pt' }}>ALFRED HELMS</div>
            </div>
            {isMobile && (
              <MenuIcon
                onClick={() => setMenuOpen(!menuOpen)}
                className={classes.root}
              />
            )}
          </div>
          {(!isMobile || menuOpen) && (
            <div className={'Navigation'}>
              <div className='Button'>
                {/*
            // @ts-ignore */}
                <Button
                  title={'Startseite'}
                  onClick={(): void => {
                    selectItem(MenuItem.HOME);
                  }}
                  selected={selectedItem === MenuItem.HOME}
                ></Button>
              </div>
              {/*               <div className='Button'>
                <Button
                  title={'Sortiment'}
                  onClick={() => selectItem(MenuItem.PLANTS)}
                  selected={selectedItem === MenuItem.PLANTS}
                ></Button>
              </div> */}
              <div className='Button'>
                {/*
            // @ts-ignore */}
                <Button
                  title={'Bilder'}
                  onClick={() => selectItem(MenuItem.IMAGES)}
                  selected={selectedItem === MenuItem.IMAGES}
                ></Button>
              </div>
              <div className='Button'>
                {/*
            // @ts-ignore */}
                <Button
                  title={'Kontakt'}
                  onClick={() => selectItem(MenuItem.CONTACT)}
                  selected={selectedItem === MenuItem.CONTACT}
                ></Button>
              </div>
              <div className='Button'>
                {/*
            // @ts-ignore */}
                <Button
                  title={'Anfahrt'}
                  onClick={() => selectItem(MenuItem.MAP)}
                  selected={selectedItem === MenuItem.MAP}
                ></Button>
              </div>
            </div>
          )}
        </header>
        {(!isMobile || !menuOpen) && (
          <div className={'Content'}>
            {/* {selectedItem === MenuItem.WIFI && <WIFI />} */}
            {selectedItem === MenuItem.CONTACT && <Contact />}
            {selectedItem === MenuItem.PLANTS && <Plants />}
            {selectedItem === MenuItem.IMAGES && <Images />}
            {selectedItem === MenuItem.HOME && <Home />}
            {selectedItem === MenuItem.MAP && <Map />}
            {selectedItem === MenuItem.IMPRINT && <Imprint />}
            {selectedItem === MenuItem.PRIVACY && <Privacy />}
            {(!isMobile || selectedItem !== MenuItem.PLANTS) && (
              <footer>
                <div>
                  <a role='button' onClick={() => selectItem(MenuItem.IMPRINT)}>
                    Impressum
                  </a>{' '}
                  |{' '}
                  <a role='button' onClick={() => selectItem(MenuItem.PRIVACY)}>
                    Datenschutz
                  </a>
                </div>
                <div>
                  © Copyright {new Date().getFullYear()} Baumschule Alfred
                  Helms. Alle Rechte vorbehalten.
                </div>
              </footer>
            )}
          </div>
        )}
        {!menuOpen && cookieInfo && (
          <div className='CookieInfo'>
            <p>
              Diese Webseite verwendet Cookies. Wenn Sie diese Webseite
              weiterhin verwenden, gehen wir von Ihrem Einverständnis aus.
            </p>
            <p style={{ whiteSpace: 'nowrap' }}>
              <MaterialButton
                variant='contained'
                onClick={() => {
                  setCookieInfo(false);
                  document.cookie = 'cookies-accepted=true';
                }}
                style={{ marginLeft: '10px' }}
              >
                Ok
              </MaterialButton>
              <MaterialButton
                variant='contained'
                onClick={() => {
                  selectItem(MenuItem.PRIVACY);
                }}
                style={{ marginLeft: '10px' }}
              >
                zur Datenschutzerklärung
              </MaterialButton>
            </p>
          </div>
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
